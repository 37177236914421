body {
    padding: 0!important;
}

small { font-size: 85%!important; }

.border-radius50 {
    border-radius: 50px!important;
}

.vh-100 {
    min-height: calc(100vh - 46px);
    box-sizing: border-box;
}

.ev-container {
    padding: 47px 0 0 50px;
    overflow-x: hidden;
}

.dropdown-menu>li>a:hover {
    background: #3498db;
}

.input-group.ev-filter-group * .dropdown .btn {
    border-right: none;
    border-left: none;
    border-radius: 0;
}
.input-group.ev-filter-group * .dropdown .btn .caret {
    margin-left: 10px;
}

.input-group.ev-filter-group * .btn-default.active, .btn-default:active,
.input-group.ev-filter-group * .open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #ffffff;
    border-color: #cacbcb;
}

.ev-chart-border-1 * .highcharts-plot-background {
    stroke-width: 1px;
    stroke: #cacbcb;
}

.ev-chart-border-1 * .highcharts-credits {
    display: none;
}

.nav-tabs.tabsV2 li a {
    color: #656666;
    padding: 5px 10px !important;
}

.nav-tabs.tabsV2 li.active>a,
.nav-tabs.tabsV2 li.active>a:focus,
.nav-tabs.tabsV2 li.active>a:hover {
    color: #3498db !important;
    border-bottom-color: transparent !important;
    cursor: default !important;
}

.display-none {
    display: none;
}

.display-grid-2-col
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.col-40px-auto
{
    grid-template-columns: 40px auto;
}

.column-gap-30px
{
    column-gap: 30px;
}

.column-gap-0_3em {
    column-gap: 0.3em;
}

.border-right-zero
{
    border-right: 0;
}

.border-left-zero
{
    border-left: 0;
}

.row-gap-30px
{
    row-gap: 30px;
}

.row-gap-15px
{
    row-gap: 15px;
}

.row-gap-120px {
    row-gap: 120px;
}

.row-gap-215px {
    row-gap: 215px;
}

.column-gap-30px
{
    column-gap: 30px;
}

.onboarding-configure-button
{
    background-color: white;
}

.onboarding-configure-button:hover
{
    background-color: #f5f5f5;
}

.stacked-grid-items  > * {
    grid-column: 1;
    grid-row: 1;
}

.active-border-color
{
    border-color: var(--default-active-color);
}

.verticle-line {
    display: grid;
    justify-content: center;
    align-items: center;
}

.verticle-line::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 98%;
    background-color: #e3e4e5;
}

.width-7em {
    width: 7em !important;
}

.width-40px {
    width: 40px;
}

.width-80px {
    width: 80px;
}

.width-400 {
    width: 400px;
}

.margin-top60px {
    margin-top: 60px;
}

.placeholder-text {
    color: var(--default-placeholder-font-color);
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600!important;
}

.font-size-42 {
    font-size:42px
}

.height-5 {
    height: 5px;
}

.height-10 {
    height: 10px;
}

.height-35 {
    height: 35px;
}

.height-40 {
    height: 40px;
}

.height-45 {
    height: 45px;
}

.height-50 {
    height: 50px;
}

.height-80 {
    height: 80px;
}

.height-90 {
    height: 90px;
}

.height-160 {
    height: 160px;
}

.height-250 {
    height: 250px;
}

.height-320 {
    height: 320px;
}

.height-350 {
    height: 350px;
}

.height-400 {
    height: 400px;
}

.height-650 {
    height: 650px;
}

.height-700 {
    height: 700px;
}

.min-listing-height {
    min-height: calc(100vh - 210px);
}

.min-container-height {
    min-height: calc(100vh - 115px);
}


/* width */
.width-10 {
    width: 10px;
}

.width-20 {
    width: 20px;
}

.width-100 {
    width: 100px;
}

.width-300 {
    width: 300px;
}

.width-720 {
    width: 720px;
}

/* Colors */
.light-green {
    background-color: #18d6b1
}
.lighter-green {
    background-color: #1aebc2
}

/* Margins */
.margin-right-60 {
    margin-right: 60px;
}

.margin-right-80 {
    margin-right: 80px;
}


.margin-left-60 {
    margin-left: 60px;
}

.margin-top-minus-350 {
    margin-top: -350px;
}

.margin-top-minus-5 {
    margin-top: -5px!important;
}

.margin-auto {
    margin: auto;
}

/* Top */
.top-0 {
    top:0;
}

/* Left */
.left-15 {
    left: 15px;
}

/* Padding */
.pad-top50 {
    padding-top: 50px;
}

/* Rotate */
.rotate-minus-137  {
    transform: rotate(-137deg);
}

/* Panel */
.panel.panel-circle-danger .circle {
    background-color: #e74c3c;
    border: 1px solid #e74c3c;
    color: #fff;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: -30px;
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.display-inline-flex{display:inline-flex}

.inset-shadow-accordion{
    -webkit-box-shadow: inset -2px 1px 8px 0px rgba(209,205,209,0.75);
    -moz-box-shadow: inset -2px 1px 8px 0px rgba(209,205,209,0.75);
    box-shadow: inset -2px 1px 8px 0px rgba(209,205,209,0.75);
}

#aceEditor {
    min-height: 700px;
    width: 100%!important;
    height: 100%!important;
    position: relative;
}

.neutron-footer {
    position: fixed;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    padding: 5px 10px 5px 50px;
    background-color: #3f3f40;
    z-index: 5;
}

.table>tbody>tr>td {
    padding-top: 15px;
    padding-bottom: 15px;
}

.online-attachments .nav-tabs li.active>a, .nav-tabs li.active>a:focus, .nav-tabs li.active>a:hover {
    border: none!important;
    background-color: #f3f4f5;
}

.online-attachments .nav-tabs li a {
    background-color: #e3e4e5;
    text-align: center;
    padding: 20px 0;
    color: #656666!important;
    border-radius: 0;
    margin-right: 0;
}

.online-attachments .nav-tabs li a i {
    display: inline-block;
    margin-left: 8px;
    margin-top: 1px;
}

.transition-1 {
    transition: 1s;
}

.hover-bg-gray-lighter:hover {
    background-color:#e3e4e5;
}

.hover-border-primary:hover {
    border-color:#3498db
}

/** flex **/
.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.colspan-all {
    column-span: all;
}

.margin-xy-auto {
    margin-top: auto;
    margin-bottom: auto;
}